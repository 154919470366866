export class MessageDto {
  email: string;
  fromNumber: string;
  message: string;
  notificationStatus: boolean;
  sid: string;
  toNumber: string;
  verificationCode: string;
  verificationStatus: boolean;
}
