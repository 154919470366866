<div class="banner_area_type3 bnr_overlay2">
  <div class="holder">
    <div class="hr">
      <div class="vc">
        <div class="banner_section_title align-center">
          <div class="section_sub_title">
            <p>Booking</p>
          </div>
          <h1>Find Your Desire Room</h1>
        </div>
      </div>
    </div>
    <div class="hr">
      <div class="vc">
        <div class="bradecrumbe">
          <ul
            class="breadcrumb justify-content-center justify-content-cd--center"
          >
            <li>
              <a routerLink="/"> Home </a>
            </li>
            <li class="active">Booking</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="main-content bkng">
  <div class="container">
    <div class="page-content">
      <div class="booking-tabs-list">
        <ul class="nav nav-tabs" id="myTab3" role="tablist">
          <li class="nav-item">
            <a class="nav-link"> <span class="nom"></span>CHOSE ROOM</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"> <span class="nom"></span> BOOKING</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"> <span class="nom"></span> CHECKOUT</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active"><span class="nom"></span> THANK YOU</a>
          </li>
        </ul>
      </div>
      <!--booking-tabs-list end-->

      <div class="row">
        <div class="col-lg-12 text-center">
          <div>
            <h2 class="mb-3 mt-4">Thank you for your booking!</h2>

            <div class="row">
              <div class="col-12">
                <ul
                  class="summery"
                  *ngIf="payments !== null && payments !== undefined"
                >
                  <li>
                    <b>Reservation No:</b>
                    {{ booking.propertyReservationNumber }}
                  </li>
                  <li>
                    <b>Full guest name: </b> {{ booking.firstName }}
                    {{ booking.lastName }}
                  </li>
                  <li>
                    <b>Email: </b> <span> {{ booking.email }}</span>
                  </li>
                  <li>
                    <b>Phone: </b><span> {{ booking.mobile }}</span>
                  </li>
                  <li>
                    <b>Selected Room: </b><span> {{ booking.roomName }}</span>
                  </li>
                  <li>
                    <b>Room Price: </b
                    ><span>
                      {{
                        booking.roomPrice | currency: currency:"symbol":"1.2-2"
                      }}
                    </span>
                  </li>
                  <li>
                    <b>No Of Person(s): </b
                    ><span> {{ booking.noOfPersons }}</span>
                  </li>
                  <li>
                    <b>No Of Child(s): </b
                    ><span> {{ booking.noOfChildren }}</span>
                  </li>

                  <li *ngIf="payment" >
                    <b>Net Amount: </b>
                    <span>
                      {{
                        payment.netReceivableAmount
                          | currency: currency:"symbol":"1.2-2"
                      }}
                    </span>
                  </li>
                  <li *ngIf="booking.taxAmount" >
                    <b>Tax Amount ({{ this.taxPercentage }}%): </b>
                    <span>
                      {{
                        booking.taxAmount | currency: currency:"symbol":"1.2-2"
                      }}</span
                    >
                  </li>
                  <li *ngIf="discountPercentage">
                    <b>Discount: </b>{{ booking.discountPercentage }}%
                  </li>
                  <li></li>
                  <li *ngIf="discountPercentage">
                    <b>Discount Amount: </b
                    >{{
                      payment.discountAmount
                        | currency: currency:"symbol":"1.2-2"
                    }}
                  </li>
                  <li>
                    <h5>
                      Total Amount:
                      <span>
                        {{
                          booking.totalAmount
                            | currency: currency:"symbol":"1.2-2"
                        }}
                      </span>
                    </h5>
                  </li>
                  <li>
                    <b>Special Notes:</b> <span> {{ booking.notes }}</span>
                  </li>
                  <li *ngIf="payment">
                    <b>Payment method:</b>
                    <span> {{ payment.paymentMode }}</span>
                  </li>

                  <li *ngIf="payment">
                    <h5 *ngIf="payment.paymentMode == 'Card'">
                      Paid amount:<span>
                        {{
                          booking.transactionChargeAmount
                            | currency: currency:"symbol":"1.2-2"
                        }}
                      </span>
                    </h5>
                  </li>
                  <li *ngIf="payment">
                    <h5 *ngIf="payment.paymentMode == 'Cash'">Payment Status:<span> Not Paid </span></h5>
                  </li>
                </ul>
              </div>
              <div class="col-md-12 mt-4">
                <button
                  class="common_btn btn_hbreffect2 text-dark"
                  type="button"
                  (click)="onGoHome()"

                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--page-content end-->
  </div>
</section>
<!--main-content end-->
