import { BusinessUser } from 'src/app/model/user';
import { Injectable } from '@angular/core';
import { Booking } from './model/booking';
import { DeliveryOption } from './model/deliveryOption';
import { Property } from './model/property';
import { TaxDetails } from './model/TaxDetails';

const PROPERTY = 'Property';
const PROPERTY_NAME = 'PropertyName';
const BOOKINGDATA = 'booking';
const BUSINESSUSER = 'business';
const PRODUCT_CART = 'productCart';
const DELIVERY_OPTION = 'delivery_option';
const SELECTED_COUNTRY = 'selectedCountry';

@Injectable()
export class TokenStorage {
  constructor() {}

  signOut() {
    window.sessionStorage.removeItem(PROPERTY_NAME);
    window.sessionStorage.clear();
  }

  public saveProperty(property: Property) {
    window.sessionStorage.removeItem(PROPERTY);
    window.sessionStorage.setItem(PROPERTY, JSON.stringify(property));
  }

  public getProperty(): Property {
    return JSON.parse(sessionStorage.getItem(PROPERTY));
  }

  public savePropertyName(name: string) {
    window.sessionStorage.removeItem(PROPERTY_NAME);
    window.sessionStorage.setItem(PROPERTY_NAME, name);
  }
  // Booking
  public saveBookingData(booking: Booking) {
    window.sessionStorage.removeItem(BOOKINGDATA);
    if (booking !== null || booking !== undefined) {
      window.sessionStorage.setItem(BOOKINGDATA, JSON.stringify(booking));
    } else {
      window.sessionStorage.setItem(BOOKINGDATA, null);
    }
  }
  public getBookingData(): Booking {
    return JSON.parse(sessionStorage.getItem(BOOKINGDATA));
  }
  public getPropertyName(): string {
    return sessionStorage.getItem(PROPERTY_NAME);
  }

  clearHotelBooking() {
    window.sessionStorage.removeItem(BOOKINGDATA);
  }


// ProductCart
clearProductCart() {
  window.sessionStorage.removeItem(PRODUCT_CART);
}

public saveProductCart(product: any[]) {
  window.sessionStorage.removeItem(PRODUCT_CART);
  if (product !== null || product !== undefined) {
    window.sessionStorage.setItem(PRODUCT_CART, JSON.stringify(product));
  } else {
    window.sessionStorage.setItem(PRODUCT_CART, null);
  }
}

public getProductCart() {
  return JSON.parse(sessionStorage.getItem(PRODUCT_CART));
}
// delivery option

public saveDeliveryOption(deliveryOption: DeliveryOption){

  window.sessionStorage.removeItem(DELIVERY_OPTION);
  if (deliveryOption !== null || deliveryOption !== undefined) {
    window.sessionStorage.setItem(DELIVERY_OPTION, JSON.stringify(deliveryOption));
  } else {
    window.sessionStorage.setItem(DELIVERY_OPTION, null);
  }
}
public saveSelectedCountry(countryCode: string) {
  window.localStorage.setItem(SELECTED_COUNTRY, countryCode);
}

public getDeliveryOption(): DeliveryOption {
  return JSON.parse(sessionStorage.getItem(DELIVERY_OPTION));
}

getTaxDetails(businessService : any , property : BusinessUser)
{

  if(businessService.taxDetails != null && businessService.taxDetails != undefined && businessService.taxDetails.length > 0)
  {
    let taxDetails = businessService.taxDetails[0].taxSlabsList;
    if(taxDetails != null && taxDetails != undefined && businessService.taxDetails.length > 0)
    {
      return businessService.taxDetails;
    }
  }
  else
  {
    let taxDetails= property.taxDetails[0].taxSlabsList;
    if(taxDetails != null && taxDetails != undefined && property.taxDetails.length > 0)
    {
      return property.taxDetails;
    }
  }

}
getTaxPercentageByTaxDetail(amount : number ,taxdetails : TaxDetails)
  {
      if(taxdetails != null && taxdetails != undefined)
      {
       let taxSlabList = taxdetails.taxSlabsList;
       if(taxSlabList != null && taxSlabList  != undefined && taxSlabList.length > 0)
       {
         let sizeOfTaxArray = taxSlabList.length;
         for (let i = 0; i < sizeOfTaxArray; i++)
          {
            let minAmount = taxSlabList[i].minAmount;
            let maxAmount= taxSlabList[i].maxAmount;
            let taxSlab= taxSlabList[i];
             if(amount >= minAmount  && amount <= maxAmount)
             {
               return taxSlab.percentage ;
               break;
             }

          }
       }
       else
       {
          return taxdetails.percentage;
       }
      }

  }
}
